import React, { useRef, useEffect } from 'react';
import Skills from './Skills';
import styles from './Experience.module.scss';
import { useSiteMetadata } from '../../hooks';

type Props = {
  title?: string,
  children: React.Node
};

const Experience = ({ title, children }: Props) => {
  const experienceRef = useRef();
  const { skills } = useSiteMetadata();

  useEffect(() => {
    experienceRef.current.scrollIntoView();
  });

  return (
    <div ref={experienceRef} className={styles['experience']}>
      <div className={styles['experience__inner']}>
        { title && <h1 className={styles['experience__title']}>{title}</h1>}
        <Skills skills={skills}/>
        <div className={styles['experience__body']}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default Experience;