// @flow strict
import React from 'react';
import styles from './Skills.module.scss';

type Props = {
  skills: {
    [string]: string,
  },
};

const Skills = ({ skills }: Props) => (
  <div><h2>Skills</h2>
    <div className={styles['skills']}>
      <div className={styles['skills__list']}>
        {Object.keys(skills).map((name) => (!skills[name] ? null : (
          <div className={styles['skills__label']}><label for={name}>{name}</label>
            <div><meter className={styles['skills__meter']} min="0" max="100" low="0" high="1" optimum="1" value={skills[name]}></meter></div>
          </div>
        )))}
      </div>
    </div>
  </div>
);

export default Skills;
